import httpsModule from "../util/http";
import {APP_BASE_URL} from "../util/constants";

const CONTROLLER_ENDPOINT = `${APP_BASE_URL}`;

const controller = {
    getUnloco: async (params) => {
        const response = await httpsModule.get(`${CONTROLLER_ENDPOINT}unloco`, {params});
        return response.data;
    },

    getUnlocos: async (params) => {
        const response = await httpsModule.get(`${CONTROLLER_ENDPOINT}unlocos`, {params});
        return response.data;
    },

    getCountries: async (params) => {
        const response = await httpsModule.get(`${CONTROLLER_ENDPOINT}country`, {params});
        return response.data;
    },

    getCommodities: async (params) => {
        const response = await httpsModule.get(`${CONTROLLER_ENDPOINT}commodity`, {params});
        return response.data;
    },

    getCategories: async () => {
        const response = await httpsModule.get(`${CONTROLLER_ENDPOINT}category`);
        return response.data;
    },

    getServices: async () => {
        const response = await httpsModule.get(`${CONTROLLER_ENDPOINT}service`);
        return response.data;
    },

    getTypes: async () => {
        const response = await httpsModule.get(`${CONTROLLER_ENDPOINT}types`);
        return response.data;
    },

    getPorts: async (filter) => {
        const response = await httpsModule.get(`${CONTROLLER_ENDPOINT}ports`, {params: {filter}});
        return response.data;
    },

    getRejectedReasons: async () => {
        const response = await httpsModule.get(`${CONTROLLER_ENDPOINT}rejected-reasons`);
        return response.data;
    }
};

export default controller;