import httpsModule from "../util/http";
import {APP_BASE_URL} from "../util/constants";

const ORGANIZATION_ENDPOINT = `${APP_BASE_URL}organization/`;

const organizationController = {
    getClients: async (nombre, user) => {
        try {
            const response = await httpsModule.get(`${ORGANIZATION_ENDPOINT}clients/getClients`, {
                params: {nombre, user},
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getClient: async (id) => {
        try {
            const response = await httpsModule.get(`${ORGANIZATION_ENDPOINT}clients/getClient`, {
                params: {id},
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getOrganizations: async (nombre, user) => {
        try {
            const response = await httpsModule.get(`${ORGANIZATION_ENDPOINT}getOrganizations`, {
                params: {nombre, user},
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getOrganization: async (id) => {
        try {
            const response = await httpsModule.get(`${ORGANIZATION_ENDPOINT}getOrganization`, {
                params: {id},
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    createOrganization: async (newOrg) => {
        try {
            const response = await httpsModule.post(`${ORGANIZATION_ENDPOINT}createOrganization`, newOrg);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    updateOrganization: async (org) => {
        try {
            const response = await httpsModule.put(`${ORGANIZATION_ENDPOINT}updateOrganization`, org);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    validateOrganization: async (taxId) => {
        try {
            const response = await httpsModule.get(`${ORGANIZATION_ENDPOINT}validate`, {
                params: {taxId},
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
};

export default organizationController;