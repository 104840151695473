import httpsModule from "../util/http";
import {APP_BASE_URL} from "../util/constants";

const CHARGECODES_ENDPOINT = `${APP_BASE_URL}chargecode`;

const chargeCodeController = {
    getChargeCodes: async (params) => {
        const response = await httpsModule.get(`${CHARGECODES_ENDPOINT}`, {params});
        return response.data;
    },

    getChargeCode: async (chargeId) => {
        const response = await httpsModule.get(`${CHARGECODES_ENDPOINT}/${chargeId}`);
        return response.data;
    },

    updateChargeCode: async (chargeCode) => {
        const response = await httpsModule.put(`${CHARGECODES_ENDPOINT}`, chargeCode);
        return response.data;
    },

    createChargeCode: async (chargeCode) => {
        const response = await httpsModule.post(`${CHARGECODES_ENDPOINT}`, chargeCode);
        return response.data;
    },
};

export default chargeCodeController;