import httpsModule from '../util/http';
import {APP_BASE_URL} from '../util/constants';
import axios from 'axios';

const USERS_ENDPOINT = `${APP_BASE_URL}user/`;

const userController = {
    login: async (user, password) => {
        const response = await axios.post(`${USERS_ENDPOINT}login`, {
            user,
            password,
        });
        return response.data;
    },

    getUsers: async () => {
        const response = await httpsModule.get(`${USERS_ENDPOINT}`);
        return response.data;
    },

    getFullUser: async (userId) => {
        const response = await httpsModule.get(`${USERS_ENDPOINT}${userId}`);
        return response.data;
    },

    getActiveUsers: async (params) => {
        const response = await httpsModule.get(`${USERS_ENDPOINT}users`, {params});
        return response.data;
    },

    getCommercials: async () => {
        const response = await httpsModule.get(`${USERS_ENDPOINT}commercials`);
        return response.data;
    },

    createUser: async (user) => {
        await httpsModule.post(`${USERS_ENDPOINT}`, user);
    },

    inactivateUser: async (id) => {
        await httpsModule.delete(`${USERS_ENDPOINT}${id}`);
    },

    updateUser: async (user) => {
        await httpsModule.put(`${USERS_ENDPOINT}`, user);
    },

    updateUserPassword: async (user) => {
        await httpsModule.put(`${USERS_ENDPOINT}password`, user);
    },

    getRelatedCommercials: async (userCode) => {
        const response = await httpsModule.get(`${USERS_ENDPOINT}related/commercials/${userCode}`);
        return response.data;
    },

    getRelatedUsers: async (userCode) => {
        const response = await httpsModule.get(`${USERS_ENDPOINT}related/users/${userCode}`);
        return response.data;
    }
};

export default userController;