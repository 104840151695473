import {IconSearch} from "@tabler/icons-react";
import Text from "../Text/Text";
import Container from "../Container/Container";
import React, {useContext} from "react";
import {LanguageContext} from "../../../context/LanguageContext";
import {TRANSLATION_KEYS} from "../../../util/constants";

export default function NotFound() {
    const {t} = useContext(LanguageContext);
    return (
        <Container styleVariant={"unstyled"} h={"100%"} justify={"center"} align={"center"} direction={"column"}>
            <IconSearch size={64} c-variant={"surface-2"}/>
            <Text size={"md"} cVariant={"surface-2"} ta={"center"}>
                {t(TRANSLATION_KEYS.NOT_FOUND)}
            </Text>
        </Container>
    )
}